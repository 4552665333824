import React, { Component } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import Login from './auth/Login'
import logo from '../images/xlab.jpg'
import LoadingDefault from './Elements/Loading/LoadingDefault'
import Api from '../Api'
import { KeDoContext } from '../kedo-context'
import image1 from '../images/image1.jpg'
import image2 from '../images/image2.jpg'
import image3 from '../images/image3.jpg'
import image4 from '../images/image4.jpg'
import image5 from '../images/image5.jpg'

export default class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            keyLogin: false,
            keyLoginFailure: false,
            bgStyle: null,
        }

        this.handleSuccessfulAuth = this.handleSuccessfulAuth.bind(this)
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
    }

    static contextType = KeDoContext

    handleSuccessfulAuth(data) {
        this.props.handleLogin(data)

        if (
            localStorage.getItem('forceUpdate') !== '3' &&
            localStorage.getItem('referer') &&
            localStorage.getItem('referer') !== '/' &&
            localStorage.getItem('referer') !== ''
        ) {
            let referer = localStorage.getItem('referer')
            localStorage.removeItem('referer')
            this.props.history.push(referer)
        } else {
            this.props.history.push('/environment')
        }
    }

    handleLogoutClick() {
        this.props.handleLogout()
    }

    componentDidMount() {
        const pictureArray = [image1, image2, image3, image4, image5]
        const randomIndex = Math.floor(Math.random() * pictureArray.length)
        const selectedPicture = pictureArray[randomIndex]

        this.setState({
            bgStyle: { backgroundImage: `url(${selectedPicture})` },
        })
        let props = this.props
        let query = new URLSearchParams(this.props.location.search)
        let api = new Api()
        if (true === query.has('key') && true === query.has('username')) {
            this.setState({ keyLogin: true })
            api.get('/key_login_check', {
                params: {
                    username: query.get('username'),
                    key: query.get('key'),
                },
            })
                .then((response) => {
                    localStorage.setItem('forceUpdate', 3)
                    this.handleSuccessfulAuth(response.data)
                })
                .catch((error) =>
                    this.setState({
                        keyLoginFailure: true,
                    })
                )
        }
    }

    render() {
        const kedo = this.context
        let query = new URLSearchParams(this.props.location.search)
        if (
            true !== this.state.keyLoginFailure &&
            true === query.has('key') &&
            true === query.has('username')
        ) {
            return (
                <Col
                    xs={12}
                    style={this.state.bgStyle}
                    className={'login outerContainer fullHeight'}
                >
                    <Row className="d-flex justify-content-center">
                        <Col
                            xs={10}
                            md={8}
                            lg={3}
                            className="mainContainer login"
                        >
                            <img
                                src={logo}
                                className={'avatar inloggen'}
                                alt={'Xlab logo'}
                            />
                            <h1>{kedo.t('Login KeDo Next Gen')}</h1>
                            <p style={{ fontSize: '16px' }}>
                                {kedo.t('auto_login')}
                            </p>
                            <LoadingDefault size={'6x'} />
                        </Col>
                    </Row>
                </Col>
            )
        }

        return (
            <Col
                xs={12}
                style={this.state.bgStyle}
                className={'login outerContainer fullHeight'}
            >
                <Row className="d-flex justify-content-center">
                    <Col xs={10} md={8} lg={3} className="mainContainer login">
                        <br />
                        {this.state.keyLoginFailure ? (
                            <Alert variant={'danger'}>
                                {kedo.t('login_incorrect')}
                            </Alert>
                        ) : null}
                        <Login
                            kedo={kedo}
                            title={kedo.t('KeDo Next Gen')}
                            handleSuccessfulAuth={this.handleSuccessfulAuth}
                        />
                        <Row>
                            <Col className={'company-footer'} md={12}>
                                <p>
                                    <span className={'company'} style={{}}>
                                        Helpdesk Politie Tel: 088-1611212 | M:{' '}
                                        <a href="mailto:servicedesk.ICT@politie.nl">
                                            servicedesk.ICT@politie.nl
                                        </a>{' '}
                                        | Helpdesk Ketenpartners: Tel:
                                        088-1611226{' '}
                                        <a href="fodp@politie.nl">
                                            fodp@politie.nl
                                        </a>
                                    </span>
                                </p>
                                <br />
                                <img
                                    src={logo}
                                    className={'avatar inloggen'}
                                    alt={'xlab logo'}
                                />
                                <span className={'company mt-2'}>
                                    {kedo.t('Powered by KeDo')}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }
}
