import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultGrid from '../../Grid/DefaultGrid'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'

import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleDown'
import { faChevronDoubleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleUp'

class SearchDefDossier extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            advancedSearch: this.props.advancedSearch
                ? this.props.advancedSearch
                : false,
            items: this.props.items,
            showArchive: this.props.showArchive,
            resetSearch: false,
            archived: null,
            dossierQuery: this.props.dossierQuery
                ? this.props.dossierQuery
                : null,
            search: this.props.search ? this.props.search : [],
        }

        this.handleAdvancedSearch = this.handleAdvancedSearch.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.updateSearchValue = this.updateSearchValue.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
    }

    handleAdvancedSearch() {
        this.setState((state) => ({
            advancedSearch: !state.advancedSearch,
        }))
    }

    onKeyPress(item, event) {
        if (event.key === 'Enter' && this.props.doSearch) {
            this.saveSearchParams(this.state.search, this.state.dossierQuery)
            this.props.doSearch(this.state.search, this.state.dossierQuery)
        }
    }

    updateSearchValue(itemId, value) {
        let utils = this.props.kedo.utils()
        let search = this.state.search
        search = search.filter((searchItem) => itemId !== searchItem.id)

        if (value && utils.getCustomlength(value) > 0) {
            search.push({
                id: itemId,
                value: value,
            })
        }

        this.setState({ search: search })
    }

    renderSearchItems() {
        return (
            <Form id={`${this.props.defDossierId}-search`}>
                <DefaultGrid
                    changeValue={this.updateSearchValue}
                    content={this.state.search}
                    errors={{}}
                    kedo={this.props.kedo}
                    onChange={this.props.onChange}
                    onKeyPress={this.onKeyPress}
                    items={this.state.items
                        .filter((item) => item.view === 'search')
                        .sort((a, b) => {
                            return a.col - b.col
                        })}
                    mode={'search'}
                    reset={this.state.resetSearch}
                />
            </Form>
        )
    }

    renderAdvancedSearchItems() {
        if (!this.state.advancedSearch) {
            return
        }

        return (
            <Form id={`${this.props.defDossierId}-advanced-search`}>
                <DefaultGrid
                    changeValue={this.updateSearchValue}
                    content={this.state.search}
                    errors={{}}
                    kedo={this.props.kedo}
                    onChange={this.props.onChange}
                    onKeyPress={this.onKeyPress}
                    items={this.state.items
                        .filter((item) => item.view === 'advanced-search')
                        .sort((a, b) => {
                            return a.col - b.col
                        })}
                    mode={'advanced-search'}
                    reset={this.state.resetSearch}
                />
            </Form>
        )
    }

    translateQuery(query) {
        if (!query || !query.translations || query.translations.length <= 0) {
            return query.uuid
        }

        let culture = this.props.kedo.env().getSelectedLocale()
        let trans = query.translations.find(
            (item) => item.culture === culture.code
        )
        if (trans) {
            return trans.search_name
        }

        return query.translations[0].search_name
    }

    renderDossierQueries() {
        if (!this.props.defDossier || !this.props.defDossier.dossierqueries) {
            return ''
        }
        let culture = this.props.kedo.env().getSelectedLocale()

        return (
            <Form.Group>
                <h6>{this.props.kedo.t('Search in')}</h6>
                <Form.Control
                    value={this.state.dossierQuery}
                    onChange={(e) => {
                        this.setState({ dossierQuery: e.target.value })

                        //Redirect if the url has a dossierQuery set
                        var url = new URL(window.location)
                        if (url.searchParams.has('dossierQuery')) {
                            if (e.target.value.length <= 0) {
                                url.searchParams.delete('dossierQuery')
                            } else {
                                url.searchParams.set(
                                    'dossierQuery',
                                    e.target.value
                                )
                            }

                            url.search = url.searchParams
                            url = url.toString()
                            history.replaceState({}, null, url)
                            //history.pushState({}, null, url)
                            if (
                                this.state.dossierQuery.length > 0 &&
                                e.target.value.length <= 0
                            ) {
                                this.setState({ loading: true })
                                window.location.reload()
                            }
                        }
                        this.saveSearchParams(
                            this.state.search,
                            e.target.value,
                            this.state.archived
                        )
                    }}
                    name={'dossierquery'}
                    as={'select'}
                >
                    <option value={''}>
                        {this.props.kedo.t('Everything')}
                    </option>
                    {this.props.defDossier.dossierqueries
                        .filter((query) => {
                            if (query.translations?.length > 0) {
                                let trans = query.translations.find(
                                    (item) => item.culture === culture.code
                                )
                                if (trans?.search_name?.length > 0) {
                                    return query
                                }
                            }
                        })
                        .map((query) => (
                            <option key={query.uuid} value={query.uuid}>
                                {this.translateQuery(query)}
                            </option>
                        ))}
                </Form.Control>
            </Form.Group>
        )
    }

    saveSearchParams(search, dossierQuery, archived) {
        const utils = this.props.kedo.utils()

        const searchItems = {
            defDossierId: this.props.defDossierId,
            advancedSearch: this.state.advancedSearch,
        }

        if (search && search.length > 0) searchItems.search = search

        if (dossierQuery && dossierQuery.length > 0)
            searchItems.dossierQuery = dossierQuery

        if (archived && archived.length > 0) searchItems.archived = archived

        if (searchItems.search || searchItems.dossierQuery || search.archived) {
            utils.createSavedSearch(searchItems)
        } else {
            utils.deleteSavedSearch(this.props.defDossierId)
        }
    }

    handleReset() {
        this.setState({ search: [], resetSearch: true })
        document.getElementById(this.props.defDossierId + '-search').reset()
        let advSearch = document.getElementById(
            this.props.defDossierId + 'advanced-search'
        )
        //Check if there is a advanced search
        if (advSearch) {
            advSearch.reset()
        }
        //Force the form to be reloaded and re-rendered
        this.setState(
            {
                dossierQuery: null,
                search: [],
                content: [],
                loading: true,
                advancedSearch: false,
            },
            () =>
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.saveSearchParams([])
                    this.props.doSearch(
                        this.state.search,
                        this.state.dossierQuery,
                        this.state.archived
                    )
                }, 100)
        )
    }

    render() {
        let advancedSearchItems = this.state.items.filter(
            (item) => item.view === 'advanced-search'
        )
        const kedo = this.props.kedo

        return (
            <Col xs={12} className={'searchBlock'}>
                {this.state.loading ? (
                    <LoadingDefault />
                ) : (
                    <Form>
                        <Row>
                            <Col
                                xs={1}
                                style={{ margin: 'auto', maxWidth: '2%' }}
                            >
                                <FontAwesomeIcon icon={faSearch} size={'lg'} />
                            </Col>
                            <Col xs={11} style={{ minWidth: '96%' }}>
                                {this.state.showArchive === true ? (
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group
                                                className={'float-right mb-2'}
                                                controlId="searchForm.selectArchived"
                                            >
                                                <Form.Control
                                                    name={'archived'}
                                                    onChange={(e) => {
                                                        this.setState(
                                                            {
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            },
                                                            () => {
                                                                this.saveSearchParams(
                                                                    this.state
                                                                        .search,
                                                                    this.state
                                                                        .dossierQuery,
                                                                    this.state
                                                                        .archived
                                                                )
                                                                this.props.doSearch(
                                                                    this.state
                                                                        .search,
                                                                    this.state
                                                                        .dossierQuery,
                                                                    this.state
                                                                        .archived
                                                                )
                                                            }
                                                        )
                                                    }}
                                                    size="sm"
                                                    as="select"
                                                    custom
                                                >
                                                    <optgroup
                                                        label={kedo.t(
                                                            'Archive'
                                                        )}
                                                    >
                                                        <option value={''}>
                                                            {kedo.t(
                                                                'Not archived'
                                                            )}
                                                        </option>
                                                        <option value={'both'}>
                                                            {kedo.t('Both')}
                                                        </option>
                                                        <option
                                                            value={'archived'}
                                                        >
                                                            {kedo.t(
                                                                'Only archived'
                                                            )}
                                                        </option>
                                                    </optgroup>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                ) : null}
                                {this.renderDossierQueries()}
                                {this.renderSearchItems()}
                                {this.state.advancedSearch ? (
                                    <hr
                                        className="hr-text"
                                        data-content={kedo.t('Advanced search')}
                                    />
                                ) : null}
                                {this.renderAdvancedSearchItems()}
                                <br />
                                <Row>
                                    <Col
                                        className={
                                            'd-md-flex justify-content-start'
                                        }
                                        xs={12}
                                        lg={
                                            advancedSearchItems.length <= 0
                                                ? 12
                                                : 6
                                        }
                                    >
                                        <Button
                                            variant={'primary'}
                                            onClick={() => {
                                                this.saveSearchParams(
                                                    this.state.search,
                                                    this.state.dossierQuery,
                                                    this.state.archived
                                                )
                                                this.props.doSearch(
                                                    this.state.search,
                                                    this.state.dossierQuery,
                                                    this.state.archived
                                                )
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                            &nbsp; {kedo.t('Search')}
                                        </Button>
                                        <Button
                                            variant={'link'}
                                            onClick={() => this.handleReset()}
                                        >
                                            {kedo.t('Reset')}
                                        </Button>
                                    </Col>
                                    {advancedSearchItems.length > 0 ? (
                                        <Col
                                            className={
                                                'd-md-flex justify-content-end'
                                            }
                                            xs={12}
                                            lg={6}
                                        >
                                            <span>
                                                <Button
                                                    variant={
                                                        'outline-secondary'
                                                    }
                                                    onClick={
                                                        this
                                                            .handleAdvancedSearch
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        className={'mr-2'}
                                                        icon={
                                                            !this.state
                                                                .advancedSearch
                                                                ? faChevronDoubleDown
                                                                : faChevronDoubleUp
                                                        }
                                                    />
                                                    {kedo.t('Advanced search')}{' '}
                                                </Button>
                                            </span>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Col>
        )
    }
}

export default SearchDefDossier
