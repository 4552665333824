import React, { Component } from 'react'
import { Alert, Col, Form, Button, Row, ButtonGroup } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import Api from '../Api'
import LoadingDefault from './Elements/Loading/LoadingDefault'
import { KeDoContext } from '../kedo-context'
import image1 from '../images/image1.jpg'
import image2 from '../images/image2.jpg'
import image3 from '../images/image3.jpg'
import image4 from '../images/image4.jpg'
import image5 from '../images/image5.jpg'

class ConfirmForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: null,
            confirmKey: null,
        }

        this.forwardToKeyLogin = this.forwardToKeyLogin.bind(this)
    }

    static contextType = KeDoContext

    forwardToKeyLogin() {
        const queryParams = new URLSearchParams(window.location.search)
        const username = queryParams.get('username')
        const confirmKey = queryParams.get('key')

        this.setState({
            username: username,
            confirmKey: confirmKey,
        })
    }

    render() {
        const kedo = this.context

        if (this.state.username && this.state.confirmKey) {
            return (
                <Redirect
                    to={`/login?username=${this.state.username}&key=${this.state.confirmKey}`}
                />
            )
        }

        return (
            <Col xs={12} className={'login outerContainer fullHeight'}>
                <Row className="d-flex justify-content-center">
                    <Col xs={10} md={8} lg={4} className="mainContainer">
                        <h1>{kedo.t('Confirm forgot password')}</h1>
                        <p>{kedo.t('confirm_forgot_password_check')}</p>
                        <ButtonGroup>
                            <Button className={'mr-2'} variant="secondary">
                                {kedo.t('No')}
                            </Button>
                            <Button onClick={this.forwardToKeyLogin}>
                                {kedo.t('Yes')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Col>
        )
    }
}

export default ConfirmForgotPassword
